<h2 class="text-center my-4">Inicio</h2>

<div class="container">
  <div class="row justify-content-left">
    <div class="col-md-3 me-3 mb-4 tile-container" *ngFor="let tile of tiles">
      <a [routerLink]="tile.link" class="tile-link">
        <img [src]="tile.icon" class="tile-icon mx-auto d-block img-fluid mb-3">
        <h6 class="tile-title">{{ tile.name }}</h6>
      </a>
    </div>
  </div>
</div>
