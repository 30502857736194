import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

import { Module } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.authService.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          this.authService.login();
          return of(false);
        }
        else {
          return of(true);
        }
        
        //const requiredModule = this.getRequiredModuleFromRoute(route.routeConfig?.path);
        //if (requiredModule) {
        //  if (this.authService.hasModuleAccess(requiredModule)) {
        //    return of(true);
        //  } else {
        //    this.router.navigate(['/error'], { queryParams: { type: '401' } });
        //    return of(false);
        //  }
        //}
        //else {

        //}
      })
    );
  }

  private getRequiredModuleFromRoute(routePath?: string): Module | null {
    switch (routePath) {
      case 'seguridad':
        return Module.Security;
      case 'catalogos':
        return Module.Configuration;
      case 'vigilancia':
        return Module.Surveillance;
      case 'laboratorio':
        return Module.Laboratory;
      case 'alertas':
        return Module.Alerts;
      default:
        return null;
    }
  }
}
