import { MenuItem } from './menu.model';
import { Module } from 'src/app/core/models/enums';

export const MENU: MenuItem[] = [
  {
    label: 'Inicio',
    icon: 'home',
    link: '/'
  },
  {
    label: 'Vigilancias',
    icon: 'eye',
    link: '/vigilancia',
    module: Module.Surveillance
  },
  {
    label: 'Catálogos',
    icon: 'database',
    link: '/catalogos',
    module: Module.Configuration
  },
  {
    label: 'Usuarios',
    icon: 'lock',
    link: '/seguridad',
    module: Module.Security
  }
];
