// ref: https://stackoverflow.com/questions/56685403/how-to-change-ngbdatepicker-date-format-from-json-to-yyyy-mm-dd

import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct | null {
        if (!value)
            return null
        let parts = value.split('/');
        return { year: +parts[0], month: +parts[1], day: +parts[2] } as NgbDateStruct;
    }

    format(date: NgbDateStruct | null): string {
        return date ? ('0' + date.day).slice(-2) + '/' + ('0' + date.month).slice(-2) + '/' + date.year : '';
    }
}
