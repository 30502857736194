import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client-ts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Module } from '../models/enums';
interface CustomUser extends User {
  modules?: any; // Define el tipo adecuado para modules
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private userManager: UserManager;
  private user: CustomUser | null;
  private modules: any[];
  private isUserLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isModulesLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  endpoint: string;

  constructor(private http: HttpClient, private router: Router) {
    const settings: UserManagerSettings = {
      authority: environment.authAuthority,
      client_id: environment.clientId,
      redirect_uri: environment.redirectUri,
      post_logout_redirect_uri: environment.postLogoutRedirectUri,
      response_type: 'code',
      scope: 'openid profile api',
      filterProtocolClaims: true,
      loadUserInfo: false,
      automaticSilentRenew: true,
      userStore: new WebStorageStateStore({ store: localStorage })
    };

    this.userManager = new UserManager(settings);
    this.endpoint = environment.sivesaApiEndpoint;

    this.userManager.events.addUserLoaded(user => {
      this.fetchUserModules(user);
      this.isUserLoadedSubject.next(true);
    });

    this.userManager.events.addUserUnloaded(() => {
      localStorage.removeItem("modules");
      this.isUserLoadedSubject.next(true);
    });

    this.loadUser().then(() => {
      if(!!this.user)
        this.fetchUserModules(this.user!);
    });
  }

  private async loadUser(): Promise<void> {
    try {
      this.user = await this.userManager.getUser();

      if (this.user && this.user.expired) {
        this.user = await this.userManager.signinSilent();
      }
    } catch (error) {
      console.error('Error al cargar el usuario:', error);
    } finally {
      this.isUserLoadedSubject.next(true);
    }
  }

  login(): void {
    this.userManager.signinRedirect();
  }

  private fetchUserModules(user: CustomUser): void {
    try {
      this.modules = <any[]>JSON.parse(localStorage.getItem("modules")!) ?? [];
      if (!this.modules.length) {
        this.http.get<any[]>(`${this.endpoint}/UserModule/${user.profile.sub}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`
          }
        })
          .subscribe(response => {
            if (Array.isArray(response)) {
              this.modules = response.map((item: any) => ({
                module: item.moduleId,
                access: item.access
              }));
              localStorage.setItem("modules", JSON.stringify(this.modules));
            } else {
              console.error('La respuesta no es un array:', response);
            }
            this.isModulesLoadedSubject.next(true);
          });
      }
      else {
        this.isModulesLoadedSubject.next(true);
      }
    } catch (error) {
      console.error('Error al obtener los módulos del usuario:', error);
    }
  }

  async completeLogin(): Promise<void> {
    this.user = await this.userManager.signinRedirectCallback();
    this.router.navigate(['/']);

  }

  logout(): void {
    this.userManager.revokeTokens(["refresh_token"]).then(() => {
      this.userManager.removeUser().then(() => {
        this.login();
      });
    }).catch((error) => {
      console.error('Error durante el logout:', error);
    });
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.isUserLoadedSubject.subscribe(isLoaded => {
        if (isLoaded) {
          observer.next(this.user != null && !this.user.expired);
          observer.complete();
        }
      });
    });
  }

  getAuthorizationHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.user?.access_token}`);
  }

  getUserName(): string | null {
    return this.user?.profile.name || null;
  }

  getUserModules(): Observable<any[]> {
    return new Observable<any[]>((observer) => {
      this.isModulesLoadedSubject.subscribe(isLoaded => {
        if (isLoaded) {
          observer.next(this.modules);
          observer.complete();
        }
      });
    });
  }

  getUserId(): number {
    return Number(this.user?.profile.sub);
  }

  hasModuleAccess(moduleId: Module): Observable<any> {
    return new Observable<any[]>((observer) => {
      this.isModulesLoadedSubject.subscribe(isLoaded => {
        if (isLoaded) {
          observer.next(this.modules.find((item: any) => item.module == moduleId));
          observer.complete();
        }
      });
    });
  }

}
