import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './components/layout/base/base.component';
import { AuthGuard } from './core/guards/auth.guard';
import { WelcomeComponent } from './components/views/welcome/welcome.component';
import { ErrorPageComponent } from './components/views/error-page/error-page.component';
import { AuthCallbackComponent } from './components/shared/auth-callback/auth-callback.component';
import { HomeComponent } from './components/views/home/home.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DevExtremeModule } from 'devextreme-angular';


const routes: Routes = [
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'welcome', component: WelcomeComponent },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'seguridad',
        loadChildren: () => import('./modules/security.module').then(m => m.SecurityModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'catalogos',
        loadChildren: () => import('./modules/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'vigilancia',
        loadChildren: () => import('./modules/surveillance.module').then(m => m.SurveillanceModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'laboratorio',
        loadChildren: () => import('./modules/laboratory.module').then(m => m.LaboratoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'alertas',
        loadChildren: () => import('./modules/alerts.module').then(m => m.AlertsModule),
        canActivate: [AuthGuard]
      },
      { 
        path: 'error',
        component: ErrorPageComponent,
        data: {
          'type': 404,
          'title': 'Página no encontrada',
          'desc': 'Oopps!! La página que estas buscando no existe'
        }
      }
    ]
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    WelcomeComponent,
    AuthCallbackComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
    NgbDatepickerModule,
    NgbModule,
    NgSelectModule,
    DevExtremeModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
