<div class="horizontal-menu">
  <nav class="navbar top-navbar">
    <div class="container-md">
      <div class="navbar-content">

        <!--- Logo -->
        <a routerLink="/" class="navbar-brand">
          <img src="../../../../assets/images/sirve.jpg" style="height: 50px; width: auto;">
        </a>
        <span class="title-navbar">SIVESA</span>
        <ul class="navbar-nav">
          <!--<li class="nav-item" ngbDropdown>
      <a class="nav-link" ngbDropdownToggle id="languageDropdown" role="button">
        <img src="assets/images/flags/es.svg" class="wd-20 me-1" title="us" alt="us">
        <span class="fw-bold ms-1 me-1 d-none d-md-inline-block">Español</span>
      </a>
      <div ngbDropdownMenu aria-labelledby="languageDropdown">
        <a ngbDropdownItem class="py-2"> <img src="assets/images/flags/us.svg" class="wd-20 me-1" title="us" alt="us"> <span class="ms-1"> English </span></a>
      </div>
    </li>-->
          <li class="nav-item nav-profile" ngbDropdown>
            <a class="nav-link btn btn-icon" ngbDropdownToggle id="profileDropdown">
              <i class="feather icon-user"></i>
            </a>
            <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
              <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
                <div class="mb-3">
                  <button class="btn btn-icon">
                    <i class="feather icon-user"></i>
                  </button>
                </div>
                <div class="text-center">
                  <p class="tx-16 fw-bolder">{{ username }}</p>
                </div>
              </div>
              <ul class="list-unstyled p-1">
                <!--<li class="dropdown-item py-2">
            <a href="" (click)="false" class="d-flex text-body ms-0">
              <i class="feather icon-edit me-2 icon-md"></i>
              <span>Edit Profile</span>
            </a>
          </li>-->
                <li class="dropdown-item py-2">
                  <a href="" (click)="onLogout($event)" class="d-flex text-body ms-0">
                    <i class="feather icon-log-out me-2 icon-lg"></i>
                    <span>Cerrar sesión</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" (click)="toggleHeaderMenu()">
          <i  class="feather icon-menu icon-lg" ></i>
        </button>

      </div>
    </div>
  </nav>

  <nav class="bottom-navbar">
    <div class="container">

      <!--- Menu -->
      <ul class="nav page-navigation">
        <ng-container *ngFor="let item of menuItems">

          <li class="nav-item" [routerLinkActive]="['active']" *ngIf="!item.isMegaMenu">
            <a class="nav-link" [routerLink]="item.link" *ngIf="!hasItems(item)">
              <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
              <span class="menu-title">{{ item.label }}</span>
            </a>
            <a href="javascript:void(0);" class="nav-link" *ngIf="hasItems(item)">
              <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
              <span class="menu-title">{{ item.label }}</span>
              <i class="link-arrow"></i>
            </a>
            <div class="submenu" *ngIf="hasItems(item)">

              <ul class="submenu-item" *ngFor="let subMenu of item.subMenus">
                <ng-container *ngFor="let subMenuItem of subMenu.subMenuItems">
                  <li class="category-heading" *ngIf="subMenuItem.isTitle">{{ subMenuItem.label }}</li>

                  <li class="nav-item" *ngIf="!subMenuItem.isTitle">
                    <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="subMenuItem.link">{{ subMenuItem.label }}</a>
                    <span class="badge bg-{{subMenuItem.badge.variant}}" *ngIf="subMenuItem.badge">{{subMenuItem.badge.text}}</span>
                  </li>
                </ng-container>
              </ul>

            </div>
          </li>

          <li class="nav-item mega-menu" [routerLinkActive]="['active']" *ngIf="item.isMegaMenu">
            <a href="javascript:void(0);" class="nav-link">
              <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
              <span class="menu-title">{{ item.label }}</span>
              <i class="link-arrow"></i>
            </a>
            <div class="submenu">
              <div class="row">
                <div class="col-md-3" *ngFor="let subMenu of item.subMenus">
                  <ul class="submenu-item">
                    <ng-container *ngFor="let subMenuItem of subMenu.subMenuItems">

                      <li class="category-heading" *ngIf="subMenuItem.isTitle">{{ subMenuItem.label }}</li>

                      <li class="nav-item" *ngIf="!subMenuItem.isTitle">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="subMenuItem.link">{{ subMenuItem.label }}</a>
                      </li>

                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>
</div>
