export enum EstablishmentType {
  Farm = 1000,
  Park = 2000,
  Other = 3000
}

export enum SurveillanceModality {
  Active = 1000,
  Passive = 2000
}

export enum SurveillanceStatus {
  Created = 1000,
  Cancelled = 1500,
  Done = 2000
}

export enum Access {
  Default = 0, //Permiso solo a los registros asignados
  Edition = 1, //Permiso de edicion
  Unfiltered = 2, //Registros completos, sin filtros
  Classified = 4, //Registros por campaña
  Localized = 8, //Registros por region
  Unassigned = 16, //Registros no asignados
  Unrestricted = 128 //Super admin
}

export enum Module {
  Security = 1, //Módulo de seguridad de usuarios
  Configuration = 2, //Módulo de catálogos
  Surveillance = 3, //Módulo de vigilancias
  Laboratory = 4, //Módulo de laboratorio
  Alerts = 5 //Módulo de alertas
}
