import { Component, OnInit } from '@angular/core';
import { Module } from 'src/app/core/models/enums';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  modules: any;
  tiles: any[] = [
    { name: 'Vigilancias', icon: 'assets/images/animal_health.png', link: '/vigilancia', module: Module.Surveillance },
    { name: 'Catálogos', icon: 'assets/images/database_icon.png', link: '/catalogos', module: Module.Configuration },
    { name: 'Usuarios', icon: 'assets/images/security_icon.png', link: '/seguridad', module: Module.Security }
  ];

  constructor(
    private authService: AuthService) { }
  
  ngOnInit(): void {
    this.authService.getUserModules().subscribe(modules => {
      this.modules = modules;
      this.tiles = this.tiles.filter(menuItem => {
        // Verifica si el módulo del menú existe en el array de módulos permitidos
        return !menuItem.module || this.modules.some((m: any) => m.module === menuItem.module);
      });
    });
  }
}

