// error-page.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  type: string | null = null;
  title: string = '';
  desc: string = '';
  private sub: Subscription = new Subscription();

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    // Leer queryParams
    this.sub = this.route.queryParams.subscribe(params => {
      this.type = params['type'] || '';

      switch (this.type) {
        case '401':
          this.title = 'Autenticación requerida';
          this.desc = 'Por favor, inicie sesión para acceder a esta página.';
          break;
        case '403':
          this.title = 'Acceso denegado';
          this.desc = 'No tiene los permisos necesarios para acceder a esta página.';
          break;
        case '404':
          this.title = 'Página No Encontrada';
          this.desc = '¡Ups! La página que buscabas no existe.';
          break;
        case '500':
          this.title = 'Error Interno del Servidor';
          this.desc = '¡Ups! Hubo un error. Por favor, inténtalo de nuevo más tarde.';
          break;
        default:
          this.title = 'Algo salió mal';
          this.desc = 'Parece que algo salió mal.<br> Estamos trabajando en ello.';
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
