<div class="main-wrapper">

  <!-- partial:navbar -->
  <app-navbar style="box-sizing: content-box !important;"></app-navbar>
  <!-- partial -->

  <div class="container-xxl pt-2">
    <div class="page-wrapper page-content">

      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Cargando...</div>
      </div>

      <div contentAnimate *ngIf="!isLoading">
        <router-outlet></router-outlet>
      </div>

    </div>

    <!-- partial:footer -->
    <app-footer></app-footer>
    <!-- partial -->

  </div>
</div>
