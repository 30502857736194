import { Component, OnInit } from '@angular/core';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuItems: MenuItem[] = [];
  username: string | null;
  modules: any;

  constructor(
    private authService: AuthService
  ) { }
  
  ngOnInit(): void {

    this.username = this.authService.getUserName();

    this.authService.getUserModules().subscribe(modules => {
      this.modules = modules;
      this.menuItems = MENU.filter(menuItem => {
        // Verifica si el módulo del menú existe en el array de módulos permitidos
        return !menuItem.module || this.modules.some((m: any) => m.module === menuItem.module);
      });
    });
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subMenus !== undefined ? item.subMenus.length > 0 : false;
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    this.authService.logout();
  }

  /**
   * Toggle header menu in tablet/mobile devices
   */
  toggleHeaderMenu() {
    document.querySelector('.horizontal-menu .bottom-navbar')!.classList.toggle('header-toggled');
  }

}
